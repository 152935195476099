import React from 'react';
import '../assets/css/styles.css';
import { apiURL, prefixDefault, denomination, projectName, decimals, prefixValidator } from '../data/API';
import moment from 'moment';
import Axios from 'axios';
import Label from '../components/Label';
import { connect } from 'react-redux';
import { getPrice } from '../redux/actions/getData';
import ReactTooltip from "react-tooltip";

class Transaction extends React.Component {
  state={}
  componentDidMount(){
    this.props.getPrice();
    Axios.get(`${apiURL}/txs/detail?hash=${this.props.match.params.hash}`)
    .then(response => {
      this.setState({
        tx: response.data.data,
      })
    })
    document.title = `Transaction ${this.props.match.params.hash} | ${projectName} Scan`;
  }
  copy(value){
    return (
      <>
      <ReactTooltip id="copy-text" type="dark">
        Copy
      </ReactTooltip>
      <i className="bi bi-stickies" data-tip data-for="copy-text" id="copy-text" onClick={() => {navigator.clipboard.writeText(value)}}></i>
      </>
    )
  }

  render() {
    return (
      <>
      <div className="summary-div" data-theme={this.props.fullData.theme}>
        {
          this.state.tx ?
          <>
            <ReactTooltip id="status-text" type="dark">
              {
                this.state.tx.status === true ?
                  "Transaction Confirmed"
                : this.state.tx.status
              }
            </ReactTooltip>
            <ReactTooltip id="date-text" type="dark">
              {moment(this.state.tx.txTime).format('DD MMMM YYYY, hh:mm:ss')}
            </ReactTooltip>
            <div className="row">
              <div className="col-md-6">
                <div className="summary shadow-sm summary-12">
                  <strong className="title-text mx-0 d-block mb-2">Transaction Details</strong>
                  {
                    this.state.tx.status === true ?
                      <span class="text-inline no-margin-text badge rounded-pill bg-success mb-2" data-tip data-for="status-text"><i class="bi bi-check-all"></i> Confirmed</span>
                    : <span class="text-inline no-margin-text badge rounded-pill bg-danger mb-2" data-tip data-for="status-text"><i class="bi bi-x"></i> Failed</span>
                  }
                  <div class="text-inline vr mx-2"></div>
                  <span class="text-inline no-margin-text mb-2 small-text" data-tip data-for="date-text"><i class="bi bi-clock-history"></i> {moment(this.state.tx.txTime).fromNow()}</span>
                  <hr className="no-margin-hr" />
                  <Label label="Hash" value={this.state.tx.txHash} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-stickies" onClick={() => {navigator.clipboard.writeText(this.state.tx.txHash)}}></i>} tooltip />
                  <Label label="Block" value={parseInt(this.state.tx.txHeight).toLocaleString()} aTo={`/block/${this.state.tx.txHeight}`} a />
                  <Label label="Type" value={<span className="capitalize-text">{this.state.tx.txMessage[0].type}</span>} badge badgeType="bg-secondary" />
                  {
                    this.state.tx.txSender ?
                      (this.state.tx.txSender).includes(prefixDefault) ?
                        (this.state.tx.txSender).includes("valoper") ?
                          <Label label="From" value={this.state.tx.txSender} aTo={`/validator/${this.state.tx.txSender}`} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-stickies" onClick={() => {navigator.clipboard.writeText(this.state.tx.txSender)}}></i>} a tooltip />
                        : <Label label="From" value={this.state.tx.txSender} aTo={`/address/${this.state.tx.txSender}`} tooltipLabel="Copy" tooltipValue={<i className="copy-icon bi bi-stickies" onClick={() => {navigator.clipboard.writeText(this.state.tx.txSender)}}></i>} a tooltip />
                      : null
                    : null
                  }
                  <div className="row">
                    <div className="col-12 col-md-2">
                      <p className="content-table-title">Total</p>
                    </div>
                    <div className="col-12 col-md-10">
                      <p className="content-table-text">{`${(parseInt(this.state.tx.txAmount)/10**decimals).toLocaleString()} `}
                      {
                        this.state.tx.txDenom ?
                          <span className="badge bg-primary">{this.state.tx.txDenom.toUpperCase()}</span>
                        : <span className="badge bg-primary">{denomination}</span>
                      }
                      </p>
                    </div>
                  </div>
                  <Label label="Fee" value={
                    Array.isArray(this.state.tx.txFee) ?
                      `${(parseInt(this.state.tx.txFee[0].amount)/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 8})} `
                    : `${(parseInt(this.state.tx.txFee)/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 8})} `
                  } denom />
                  <Label label="Gas" tooltipValue={`${parseInt(this.state.tx.txGasUsed).toLocaleString()} / ${parseInt(this.state.tx.txGasWanted).toLocaleString()}`} tooltipLabel={`${(parseInt(this.state.tx.txGasUsed)/parseInt(this.state.tx.txGasWanted)*100).toLocaleString(undefined, {maximumFractionDigits: 2})}%`} tooltip />
                  {
                    this.state.memo ?
                      <Label label="Memo" value={this.state.tx.txMemo} />
                    : null
                  }
                </div>
              </div>
              <div className="col-md-6 message-div overflow-custom">
                {
                  this.state.tx.txMessage.map(txMessage=> {
                    return (
                      <div className="summary shadow-sm summary-12">
                        <strong className="title-text mx-0 d-block mb-2 capitalize-text">{txMessage.type}</strong>
                        <hr/>
                        {
                          txMessage.message.map(message=> {
                            return (
                              <>
                              {
                                message.key !== "@type" ?
                                  <div className="row">
                                    <div className="col-md-3 col-12">
                                      <p className="content-table-title capitalize-text">{message.key.replace(/_/g, " ")}</p>
                                    </div>
                                    <div className="col-md-9 col-12">
                                      {
                                        typeof message.value === "object" ?
                                          message.value ?
                                            message.key === "amount" || message.key === "value" || message.key === "bridge_fee" || message.key === "initial_deposit" ?
                                              message.value.denom && message.value.amount ?
                                                <p className="content-table-text">{(parseInt(message.value.amount)/10**decimals).toLocaleString({undefined, maximumFractionDigits: 8})}
                                                {
                                                  message.value.denom.includes("base") && message.value.denom.length > 5 ?
                                                    ` ${message.value.denom.split("base")[1].toUpperCase()}`
                                                  : message.value.denom[0] === "a" || message.value.denom[0] === "u" || message.value.denom[0] === "t" ?
                                                    ` ${message.value.denom.substring(1).toUpperCase()}`
                                                  : ` ${message.value.denom === "loki" ? "ODIN" : message.value.denom.toUpperCase()}`
                                                }
                                                </p>
                                              : message.value[0].denom && message.value[0].amount ?
                                                <p className="content-table-text">{(parseInt(message.value[0].amount)/10**decimals).toLocaleString({undefined, maximumFractionDigits: 8})}
                                                {
                                                  message.value[0].denom.includes("base") && message.value[0].denom.length > 5 ?
                                                    ` ${message.value[0].denom.split("base")[1].toUpperCase()}`
                                                  : message.value[0].denom[0] === "a" || message.value[0].denom[0] === "u" || message.value[0].denom[0] === "t" ?
                                                    ` ${message.value[0].denom.substring(1).toUpperCase()}`
                                                  : ` ${message.value.denom === "loki" ? "ODIN" : message.value.denom.toUpperCase()}`
                                                }
                                                </p>
                                              : null
                                            : <pre className="content-table-text">{JSON.stringify(message.value, undefined, 2)}</pre>
                                          : null
                                        : message.value.substring(0, 2) === "0x" && message.value.length === 42 ?
                                          <p className="content-table-text"><a href={`https://goerli.etherscan.io/address/${message.value}`} className="content-table-a" target="_blank" rel="noreferrer">{message.value}</a> {this.copy(message.value)}</p>
                                        : message.value.includes(prefixDefault+"1") ?
                                          <p className="content-table-text"><a href={`/address/${message.value}`} className="content-table-a">{message.value}</a> {this.copy(message.value)}</p>
                                        : message.value.includes(prefixValidator+"1") ?
                                          <p className="content-table-text"><a href={`/validator/${message.value}`} className="content-table-a">{message.value}</a> {this.copy(message.value)}</p>
                                        : message.key === "amount" ?
                                          <p className="content-table-text">{(parseInt(message.value)/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 8})}</p>
                                        : message.key === "denom" ?
                                          <p className="content-table-text">
                                          {
                                            message.value.includes("base") && message.value.length > 5 ?
                                              ` ${message.value.split("base")[1].toUpperCase()}`
                                            : message.value[0] === "a" || message.value[0] === "u" || message.value[0] === "t" ?
                                              ` ${message.value.substring(1).toUpperCase()}`
                                            : ` ${message.value === "loki" ? "ODIN" : message.value.toUpperCase()}`
                                          }
                                          </p>
                                        : isNaN(message.value) === false ?
                                          <p className="content-table-text">{parseInt(message.value).toLocaleString({undefined, maximumFractionDigits: 8})}</p>
                                        : <p className="content-table-text">{message.value}</p>
                                      }
                                    </div>
                                  </div>
                                : null
                              }
                              </>
                            )
                          })
                        }
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </>
          :
          <div className="summary shadow-sm" style={{"textAlign": "center", height: "622px"}} data-theme={this.props.fullData.theme}>
            <div style={{margin: "0 auto", marginTop: "275px"}}>
              <div class="o1"></div>
              <div class="o2"></div>
              <div class="o3"></div>
            </div>
          </div>
        }
      </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    fullData: state.user
  }
};

const mapDispatchToProps = {
  getPrice
}

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);