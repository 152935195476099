import React from 'react';
import {a} from 'react-router-dom';
import '../assets/css/styles.css';
import odin from "../assets/images/odin.png";
import odin_white from "../assets/images/odin_white.png";
import Axios from 'axios';
import { apiURL, prefixDefault, prefixValidator, ibcEnable } from '../data/API';
import {getPathName, setTheme} from '../redux/actions/getData';
import { connect } from 'react-redux';
import ReactTooltip from "react-tooltip";

class Header extends React.Component {
  state = {}
  componentWillMount() {
    this.props.getPathName(window.location.pathname);
    if(localStorage.getItem("theme")){
      if(localStorage.getItem("theme") === "light"){
        this.props.setTheme("light", "#FDFEFE", "#000", "#fff")
        document.body.style.backgroundColor = "#F6F6F6";
      }else if(localStorage.getItem("theme") === "dark"){
        this.props.setTheme("dark", "#212a2e", "#fff", "#1f2a42")
        document.body.style.backgroundColor = "#1f2a42";
      }
    }else{
      this.props.setTheme("light", "#FDFEFE", "#000", "#fff")
      document.body.style.backgroundColor = "#F6F6F6";
      localStorage.setItem("theme", "light");
    }
  }
  changePath = (pathName) => {
    this.props.getPathName(pathName)
  }
  searchHandle(searchString){
    if(searchString){
      if(Number.isInteger(parseInt(searchString)) && searchString.length < 44){
        window.location.replace(`/block/${parseInt(searchString)}`);
      }else if(searchString.length === prefixDefault.length+39 && searchString.includes(prefixDefault)){
        window.location.replace(`/address/${searchString}`);
      }else if(searchString.length === prefixValidator.length+39 && searchString.includes(prefixValidator)){
        window.location.replace(`/validator/${searchString}`);
      }else if(searchString.length === 64){
        Axios.get(`${apiURL}/search/detail?hash=${searchString}`)
        .then(getType => {
          if(getType.data.result){
            if(getType.data.data === 'transaction'){
              window.location.replace(`/tx/${searchString}`);
            }else if(Number.isInteger(getType.data.data)){
              window.location.replace(`/block/${getType.data.data}`);
            }else if(getType.data.data === 'ibc'){
              window.location.replace(`/ibc/${searchString}`);
            }
          }
        })
      }
    }
  }
  changeTheme(){
    if(this.props.fullData.theme === "dark"){
      this.props.setTheme("light", "#FDFEFE", "#000", "#1f2a42")
      document.body.style.backgroundColor = "#F6F6F6";
      localStorage.setItem("theme", "light");
    }else if(this.props.fullData.theme === "light"){
      this.props.setTheme("dark", "#212a2e", "#fff", "#fff")
      document.body.style.backgroundColor = "#1f2a42";
      localStorage.setItem("theme", "dark");
    }
  }
  render(){
    return(
      <>
      <ReactTooltip id="mode-text" type={this.props.fullData.theme}>
        {
          this.props.fullData.theme === "dark" ?
            "Light Mode"
          : "Night Mode"
        }
      </ReactTooltip>
      <div className="header shadow-sm" data-theme={this.props.fullData.theme}>
        <div className="header-left">
          <a href="/" onClick={() => this.changePath("/")} className="thecodes-text"><img src={this.props.fullData.theme === "dark" ? odin_white : odin} alt="ODIN Protocol Block Explorer" className="thecodes-header" /></a>
          <div className="header-mode" data-tip data-for="mode-text" onClick={() => this.changeTheme()}>
            {
              this.props.fullData.theme === "dark" ?
              <i className="bi bi-sun"></i>
            : <i className="bi bi-moon-stars"></i>
            }
          </div>
          <div className="header-menu-show">
            <i className="bi bi-text-left"></i>
          </div>
          <div className="header-right">
            <div className="search-header">
              <input type="text" placeholder="Search by block number, hash, or address" value={this.state.searchString} onKeyDown={(e) => e.key === "Enter" ? this.searchHandle(this.state.searchString) : null} onChange={e => this.setState({searchString: e.target.value})} />
              <button onClick={() => this.searchHandle(this.state.searchString)} type="button"><i className="icon bi-search"></i></button>
            </div>
            <a className="header-a" href="https://ibcscan.net/" target="_blank" rel="noreferrer">
              <div className="header-menu">
                IBC
              </div>
            </a>
            <a className="header-a" href="/validators" onClick={() => this.changePath("/validators")}>
              <div className="header-menu">
                Validators
              </div>
            </a>
            {/* <a className="header-a" href="/proposal" onClick={() => this.changePath("/validators")}>
              <div className="header-menu">
                Proposals
              </div>
            </a> */}
            {
              ibcEnable ?
              <>
                <a className="header-a" href="/ibcs" onClick={() => this.changePath("/ibcs")}>
                  <div className="header-menu">
                    IBC
                  </div>
                </a>
                <a className="header-a" href="/tokens" onClick={() => this.changePath("/tokens")}>
                  <div className="header-menu">
                    Tokens
                  </div>
                </a>
              </>
              : null
            }
          </div>
        </div>
        <div className="header-search">
          <input type="text" placeholder="Search by block number, hash, or address" value={this.state.searchString} onKeyDown={(e) => e.key === "Enter" ? this.searchHandle(this.state.searchString) : null} onChange={e => this.setState({searchString: e.target.value})} />
          <button onClick={() => this.searchHandle(this.state.searchString)} type="button"><i className="icon bi-search"></i></button>
        </div>
      </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    pathName: state.user.pathName,
    fullData: state.user
  }
};

const mapDispatchToProps = {
  getPathName,
  setTheme
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);